define("discourse/plugins/discourse-primero-hub-autologin/discourse/initializers/init-autologin", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-primero-hub-autologin",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        const siteSettings = api.container.lookup("site-settings:main");
        api.onPageChange(() => {
          if (api.getCurrentUser()) {
            return;
          }
          (0, _ajax.ajax)("https://hub.primero.org/check", {
            xhrFields: {
              withCredentials: true
            }
          }).then(() => {
            window.location = `/session/sso?return_path=${siteSettings.primero_hub_url}`;
          }, () => {});
        });
      });
    }
  };
});